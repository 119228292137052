import { getImageDimensions, getVideoMetadata } from '@shared/services/Utils';
import { api } from '@shared/config/axios';

export default {
  getUploadUrl({ type, filename }) {
    return api.$get('/presigned', {
      params: { type, filename },
    });
  },
  uploadFileToS3({ type, file }, onUploadProgress) {
    return this.getUploadUrl({ type, filename: file.name })
      .then(({ data }) => {
        const headers = { 'x-amz-acl': 'public-read' };
        return api.$put(data.url, file, { headers, onUploadProgress })
          .then(() => data.url.split('?')[0]);
      });
  },
  upload(params, onUploadProgress) {
    let uploadData = {};

    return Promise.resolve()
      .then(() => {
        if (params.type === 'image') {
          return getImageDimensions(params.file);
        }

        if (params.type === 'video') {
          return getVideoMetadata(params.file);
        }

        return {};
      })
      .then((data) => {
        uploadData = data;
        return this.uploadFileToS3(params, onUploadProgress);
      })
      .then((url) => api.$post('/upload', {
        url,
        type: params.type,
        fileable_id: params.fileableId,
        fileable_type: params.fileableType,

        size: params.file.size,
        original_name: params.file.name,
        mime_type: params.file.type,
        width: uploadData.width,
        height: uploadData.height,
        duration: uploadData.duration,
        bitrate: uploadData.bitrate,
      }));
  },
};
