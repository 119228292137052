<script>
import Draggable from 'vuedraggable';
import { genericForms } from '@shared/mixins/forms';
import APIUpload from '@shared/services/API/Upload';
import APIStore from '@app/services/API/Store';

const forms = {
  name: {
    label: 'Nom de la ressource',
    type: 'text',
    column: 'is-12 pb-0',
    inputAttrs: {
      hasCounter: false,
      maxlength: 128,
      required: true,
    },
  },
  link: {
    label: 'Lien (https://)',
    type: 'text',
    column: 'is-12 pb-0',
    inputAttrs: {
      pattern: '^https?:\\/\\/.+',
      required: true,
    },
  },
  file: {
    label: 'Fichier',
    type: 'upload',
    column: 'is-12 pb-0',
    isImg: false,
    inputAttrs: {
      required: true,
    },
  },
};

export default {
  mixins: [genericForms],
  components: { Draggable },
  data() {
    return {
      resource: { name: '', link: '', file: '' },
      resources: [],
      chosenType: 'LINK',
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
    formFields() {
      if (this.chosenType === 'LINK') {
        return { name: forms.name, link: forms.link };
      }

      return { name: forms.name, file: forms.file };
    },
    dynamicResources() {
      return this.resources.map((resource) => {
        let href;

        if (resource.type === 'LINK' || resource.path.startsWith('http')) {
          href = resource.path;
        } else {
          href = `${this.$env.apiResourcesURL}/${resource.path}`;
        }

        resource.href = href;
        return resource;
      });
    },
  },
  watch: {
    chosenType() {
      this.resource.link = '';
      this.resource.file = '';
    },
  },
  methods: {
    reorder() {
      const resources = this.resources.map((resource, k) => {
        resource.order = k + 1;
        return resource;
      });

      this.reorderPromise = this.reorderPromise || Promise.resolve();
      this.reorderPromise
        .then(() => APIStore.reorderResources(this.authStore.uuid, resources))
        .then(() => this.$showMessage.success());
    },
    deleteResource(resource) {
      const loader = this.$buefy.loading.open({ container: this.$refs.box });
      APIStore.deleteResource(this.authStore.uuid, resource.id)
        .then(() => {
          this.resources = this.resources.filter((v) => v.id !== resource.id);
          this.$showMessage.success();
        })
        .finally(() => loader.close());
    },
    action() {
      let promise;
      const resource = { name: this.resource.name, target: 'CUSTOMER' };
      const { uuid } = this.authStore;
      const link = this.resource.link.trim();

      if (link) {
        resource.link = link;
        promise = APIStore.addResource(uuid, resource);
      } else if (this.resource.file) {
        const params = {
          type: 'resource',
          file: this.resource.file,
          fileableType: 'STORE',
        };
        promise = APIUpload.upload(params)
          .then((file) => {
            resource.file = file.url;
            return APIStore.addResource(uuid, resource);
          });
      }

      return promise.then((data) => {
        this.resources.push(data.data);
        this.resource = { name: '', link: '', file: '' };
      });
    },
    loadResources() {
      const loader = this.$buefy.loading.open({ container: this.$refs.box });
      APIStore.getResources(this.authStore.uuid)
        .then(({ data }) => (this.resources = data))
        .finally(() => loader.close());
    },
  },
  mounted() {
    this.loadResources();
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #content>
      <h2 class="is-size-5">
        Vos contenus premium
        <AppFeatureBadge class="ml-2" position="is-right" />
      </h2>
      <p>
        Ajoutez des liens et des fichiers privés qui
        seront <strong>accessibles dans l'espace de vos apprenants</strong>,
        directement dans leur menu.
      </p>
      <p>
        Vous pouvez par exemple leur partager les éléments suivants :
      </p>
      <ul>
        <li>Un lien vers votre règlement intérieur</li>
        <li>Un kit à télécharger</li>
        <li>Un lien vers un site privé</li>
        <li>Un lien vers un planning</li>
        <li>Un lien vers une discussion Slack, Discord, Telegram, WhatsApp...</li>
        <li>Un lien vers un groupe Facebook, Meetup...</li>
      </ul>
    </template>

    <template #form>
      <GoProWrapper>
        <div ref="box" class="box is-relative">
          <form class="columns is-multiline" @submit.prevent="dataIsValid(isLoading) && handle()">
            <div class="column is-12 pb-0">
              <b-field label="Type de ressource">
                <b-select v-model="chosenType" expanded>
                  <option value="LINK">Lien</option>
                  <option value="FILE">Fichier</option>
                </b-select>
              </b-field>
            </div>
            <div v-for="(field, k) in formFields" :key="k" class="column" :class="field.column">
              <BaseField v-model="resource[k]" :field="field" />
            </div>
            <div class="column is-12">
              <b-button type="is-primary" native-type="submit" :loading="isLoading" expanded>
                Ajouter
              </b-button>
            </div>
          </form>
        </div>

        <draggable
          v-if="resources.length"
          v-model="resources"
          tag="ul"
          class="box"
          handle=".handle"
          @change="reorder"
        >
          <li
            v-for="resource in dynamicResources"
            :key="resource.id"
            class="level px-2 mb-2 border-dashed bradius-4 is-unselectable"
          >
            <div class="level-left">
              <b-icon class="handle cursor-grab" icon="grip-lines" />
              <a
                class="is-block p-2 has-text-clipped w-200 has-text-left color-inherit"
                :href="resource.href"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ resource.name }}
              </a>
            </div>
            <div class="level-right">
              <b-button
                type="is-text has-text-danger"
                icon-left="times-circle"
                @click="deleteResource(resource)"
              />
            </div>
          </li>
        </draggable>
      </GoProWrapper>
    </template>
  </AppFeatureWrapper>
</template>
