<script>
import { storeForms } from '@app/mixins/forms';

export default {
  mixins: [storeForms],
  i18n: {
    messages: {
      fr: {
        order: {
          CREATED_AT_ASC: "Les formations s'afficheront de la première acquise à la dernière.",
          CREATED_AT_DESC: "Les formations s'afficheront de la dernière acquise à la première.",
          ALPHANUM_ASC: "Les formations s'afficheront dans l'ordre alphabétique.",
          ALPHANUM_DESC: "Les formations s'afficheront dans l'ordre anti-alphabétique.",
          TRAININGS_ORDER: "Les formations s'afficheront dans l'ordre que vous avez défini dans la page <strong>Formations</strong> du menu principal.",
        },
      },
    },
  },
  data() {
    return {
      store: {
        feature_options: {
          appearance: {
            customer_trainings_order: 'created_at_desc',
          },
        },
      },
    };
  },
  computed: {
    orderExplanation() {
      return this.$t(`order.${this.store.feature_options.appearance.customer_trainings_order}`);
    },
  },
};
</script>

<template>
  <AppFeatureWrapper>
    <template #content>
      <h2 class="is-size-5">
        Ordre des formations
        <AppFeatureBadge class="ml-2" position="is-right" />
      </h2>
      <p>
        Choisissez l'ordre dans lequel s'affichent les formations acquises
        par l'apprenant dans <strong>son menu</strong> ainsi que sur
        <strong>son tableau de bord</strong>
      </p>
    </template>

    <template #form>
      <GoProWrapper>
        <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
          <b-field label="Ordre de tri">
            <b-select
              v-model="store.feature_options.appearance.customer_trainings_order"
              expanded
            >
              <option value="CREATED_AT_ASC">Par date d'achat (croissant)</option>
              <option value="CREATED_AT_DESC">Par date d'achat (décroissant)</option>
              <option value="ALPHANUM_ASC">Par ordre alphabétique (A - Z)</option>
              <option value="ALPHANUM_DESC">Par ordre alphabétique (Z - A)</option>
              <option value="TRAININGS_ORDER">Manuel</option>
            </b-select>
          </b-field>
          <div class="field">
            <div class="notification" v-html="orderExplanation" />
          </div>
          <b-field class="has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </b-field>
        </form>
      </GoProWrapper>
    </template>
  </AppFeatureWrapper>
</template>
