<script>
import StoreTrainingMenuForm from '@app/components/StoreTrainingMenuForm.vue';
import StoreInternalLinksForm from '@app/components/StoreInternalLinksForm.vue';

export default {
  components: {
    StoreTrainingMenuForm,
    StoreInternalLinksForm,
  },
  head: {
    title: 'Apparence de l\'espace apprenant',
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
};
</script>

<template>
  <section>
    <StoreTrainingMenuForm />
    <StoreInternalLinksForm />
  </section>
</template>
